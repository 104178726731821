import { useRouter } from 'next/router';
import { GetStaticPropsContext } from 'next';
import { Shell } from '../../components/shell';
import Blog from '../../components/blog';
import { createClient } from '../../prismic-configuration';
import { MetaData } from '../../components/seo/metadata';
import {
  fetchCategories,
  getCategoriesListfromResult,
  getTags,
  fetchBlogPosts,
} from '../../utils/blog';

const custom_blog_home_type = 'blog';

function BlogPage({ layout, page, categories, documentTagsFromPrismic }) {
  const router = useRouter();
  return (
    <>
      <MetaData page={page} layout={layout} path={router.asPath} />
      <Shell layout={layout}>
        <Blog
          categories={categories}
          documentTagsFromPrismic={documentTagsFromPrismic}
          blogType={custom_blog_home_type}
        />
      </Shell>
    </>
  );
}

export async function getStaticProps({
  locale,
  previewData,
}: GetStaticPropsContext<{ uid: string }, any>) {
  const client = createClient({ previewData });
  try {
    // fetch blogs
    const queryResult = await fetchBlogPosts(client, custom_blog_home_type);

    // fetch categories
    const categoryResult = await fetchCategories(
      client,
      'learning_topic_category'
    );

    // get Categories List & the list of category ID's from Result
    const { categories, categoryIds } = await getCategoriesListfromResult(
      categoryResult
    );

    // getTags using the list of category ID's
    const queryTags = await getTags(client, categoryIds);

    return {
      props: {
        page: queryResult,
        categories,
        messages: {
          page: { ...require(`../../i18n/pages/blog/${locale}.json`) },
          shared: { ...require(`../../i18n/shared/${locale}.json`) },
        },
        documentTagsFromPrismic: queryTags,
        blogType: custom_blog_home_type,
      },
      revalidate: 30,
    };
  } catch {
    return {
      redirect: {
        destination: '/blog',
      },
      revalidate: 30,
    };
  }
}

export default BlogPage;
